<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <div class="title">{{ !nueva_incidencia.id ? 'Agrega' : 'Editar' }} incidencia</div>
      <div class="body">
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Tipo de incidencia</label>
          <div class="col-sm-9">
            <select v-model="nueva_incidencia.subtipo" name="" id="" class="form-control">
              <option value="na">Selecciona una incidencia</option>
              <option v-for="incidencia in catalogo.opciones" :key="incidencia.id" :value="incidencia.valor">{{ incidencia.nombre }}</option>
            </select>
          </div>
        </div>

        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Descripción</label>
          <div class="col-sm-9"><textarea v-model="nueva_incidencia.descripcion" name="" id="" cols="30" rows="10" class="form-control"></textarea></div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="guardar_incidencia">{{ !nueva_incidencia.id ? 'Agrega' : 'Guardar' }}</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitud from '@/apps/formalizacion/api/v2/solicitudes'
  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
          }
        }
      }
      ,incidencia: {
        type: Object
        ,default() {
          return {
            id: null
            ,subtipo: null
          }
        }
      }
    }
    ,data() {
      return {
        catalogo: {
          opciones: []
        }
        ,nueva_incidencia: {
          subtipo: 'na'
          ,descripcion: null
        }
      }
    }
    ,mounted() {
      this.obtener_catalogo();

      if (this.incidencia.subtipo)
        this.nueva_incidencia = this.incidencia;
    }
    ,methods: {
      async obtener_catalogo() {
        try {
          if (!this.$store.state.formalizacion || !this.$store.state.formalizacion.incidencias_clg) {
            this.catalogo = (await apiCatalogo.buscar_catalogo('incidencias_clg')).data;

            this.$store.commit('set',{formalizacion: {incidencias_clg: this.catalogo}});
            this.$log.info('catalogo', this.catalogo);
          }else {
            this.catalogo = this.$store.state.formalizacion.incidencias_clg;
          }
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },guardar_incidencia() {
       this.$emit('agregar', this.nueva_incidencia);
      }
    }
  }
</script>